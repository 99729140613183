<template>
  <ion-page >
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>薩爾達傳說~曠野之息~食譜快速檢索器</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true" class="background-image">
        
      <ion-list>
        <ion-item>
          <ion-select placeholder="選擇食材" @ionChange="onIdtChanged()" v-model="selectidt">
            <ion-select-option v-for="(idt, index) in idtlist" :key="index" :value="idt" >{{idt}}</ion-select-option>
          </ion-select>
          <ion-button @click="reSetIdt()">取消選擇</ion-button>
        </ion-item>
          <ion-button id="open-modal" expand="block">效果</ion-button>
      </ion-list>
  
        <ion-modal ref="modal" trigger="open-modal">
          <ion-content>
            <ion-toolbar>
              <ion-title>效果</ion-title>
              <ion-buttons slot="end">
                <ion-button color="light" @click="dismiss()">關閉</ion-button>
              </ion-buttons>
            </ion-toolbar>
            <ion-list v-for="(effect, index) in effectlist" :key="index">
              <ion-item>
                  <ion-label>
                  <h2>{{effect}}</h2>
                </ion-label>
                <ion-checkbox id="terms" @ionChange="onTermsChanged(effect)" :checked="checkFilter(effect)"></ion-checkbox>
              </ion-item>
            </ion-list>
          </ion-content>
        </ion-modal>
        
      <div>
        <RecipeCard :data="recipedata" />
    </div>
  
    </ion-content>
    

  </ion-page>
</template>

<script>
import { 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonTitle, 
  IonToolbar,
  IonButtons,
  IonButton,
  IonModal,
  IonItem,
  IonList,
  IonLabel,
  IonCheckbox,
  IonSelect, 
  IonSelectOption

  } from '@ionic/vue';
import { defineComponent } from 'vue';
import RecipeData from '@/asset/data.json';
import RecipeCard from '@/components/RecipeCard.vue';

export default defineComponent({
  name: 'HomePage',
  data () {
    return {
      recipedata : RecipeData['rows'],
      idtlist : [],
      effectlist : [],
      //canDismiss: true,
      presentingElement: undefined,
      selectidt: '',
      effectfilter: [],
    }
  },
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonModal,
    IonItem,
    IonList,
    IonLabel,
    IonCheckbox,
    IonSelect, 
    IonSelectOption,
    RecipeCard,
  },
  mounted(){
    this.setEffcetData();
    this.setIdtData();
    this.effectfilter = this.effectlist;
    (this.effectfilter).push('--')
    
    
  },
  methods: {
    reSetIdt(){
      this.selectidt = '';
    },
    setEffcetData(){
      let maineffectlist = [...new Set(RecipeData['columns']['main_effect'])];
      let secondeffectlist = [...new Set(RecipeData['columns']['second_effect'])];
      let effectlist = maineffectlist.concat(secondeffectlist);
      effectlist = effectlist.filter(function(item) {
        return item !== '--';
      });
      this.effectlist = effectlist;
    },
    setIdtData(){
      let idt_1 = [...new Set(RecipeData['columns']['idt_1'])];
      let idt_2 = [...new Set(RecipeData['columns']['idt_2'])];
      let idt_3 = [...new Set(RecipeData['columns']['idt_3'])];
      let idt_4 = [...new Set(RecipeData['columns']['idt_4'])];
      let idt_5 = [...new Set(RecipeData['columns']['idt_5'])];

      let idt = idt_1.concat(idt_2,idt_3,idt_4,idt_5);
      idt = idt.filter(function(item) {
        return item !== '--';
      });
      this.idtlist = idt;
    },
    setRecpieData(){
      let _selectidt = this.selectidt;
      let _effectfilter = this.effectfilter;
      //主效果篩選
      let recipedata = RecipeData['rows'].filter(function(item){
        return  _effectfilter.indexOf(item.main_effect) >= 0 ;    
      });

      recipedata = recipedata.filter(function(item){
        return  _effectfilter.indexOf(item.second_effect) >= 0 ;    
      });


      if (_selectidt !== ''){
        //食材篩選
        recipedata = recipedata.filter(function(item){
          return item.idt_1 == _selectidt || item.idt_2 == _selectidt || item.idt_3 == _selectidt || item.idt_4 == _selectidt || item.idt_5 == _selectidt ;    
        });
      }
      this.recipedata = recipedata;
    },
    dismiss() {
      this.$refs.modal.$el.dismiss();
    },
    onTermsChanged(value) {
      //this.canDismiss = CheckboxCustomEvent.detail.checked;
      if(this.effectfilter.indexOf(value) !== -1){
        //移除
        let arr = this.effectfilter;
        arr = arr.filter(function(item) {
            return item !== value
        });
        this.effectfilter = arr;
      }else{
        this.effectfilter.push(value);
      }
      this.setRecpieData();
    },
    onIdtChanged() {
      //this.selectidt = value;      
      this.setRecpieData();
    },
    checkFilter(value){
      if(this.effectfilter.indexOf(value) >= 0){
        return true;
      }else{
        return false;
      }
    }
    
    

  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}

ion-modal {
  --height: 50%;
  --border-radius: 16px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal::part(backdrop) {
  background: rgba(209, 213, 219);
  opacity: 1;
}

ion-modal ion-toolbar {
  --background: rgb(14 116 144);
  --color: white;
}

ion-content{
  --background: url(https://3.bp.blogspot.com/-KXyvbGYg8Jg/WfWpK1QlreI/AAAAAAAAcsU/oOcSA-AzReUfUpLL88cA7WVN5yL_6wddgCLcBGAs/s1600/zelda.jpg)  center fixed;
}

ion-toolbar{
  --background: #969456;
  --color: #FFFFFF;
}

ion-card{
  --background: #FFFFFF;
}

</style>

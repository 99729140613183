<template>
	<ion-grid class="card">
		<ion-row>
			<ion-col class="ion-align-items-center" v-for="(card, index) in data" :key="index" size-xs="12" size-sm="12" size-lg="2" >
				<ion-card>
					<ion-card-header>
						<img class="cardimage" :src="card.image_url" width="90" height="90"/>
						<ion-card-title color="primary">{{card.recipe_ch}}</ion-card-title>
						<ion-card-subtitle color="secondary">{{card.recipe_jp}}</ion-card-subtitle>
					</ion-card-header>
					<ion-card-content>
						<p>{{card.main_effect}}
						/
						{{card.second_effect}}</p>
						<hr>
						{{card.idt_1}}
						<br>
						{{card.idt_2}}
						<br>
						{{card.idt_3}}
						<br>
						{{card.idt_4}}
						<br>
						{{card.idt_5}}
					</ion-card-content>
				</ion-card>
			</ion-col>
		</ion-row>
	</ion-grid>
</template>


<script >
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,IonCol, IonGrid, IonRow } from '@ionic/vue';
import { defineComponent } from 'vue';
export default defineComponent({
	name: 'card-motto',
	components: {  IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,IonGrid,IonRow,IonCol },
	props: {
			data: {
				type: Array,
				required: true,
				default: () => []
			}
		}
});
</script>

<style scoped>
.card{
	text-align:center;
}
.cardimage{
	border: 10px double #444;
    border-radius: 5px;
}
</style>
